<template>
  <div id="webviewer" ref="viewer"></div>
</template>

<script>
import WebViewer from '@pdftron/webviewer';
export default {
  name: 'WebViewer',
  props: { initialDoc: { type: String } },
  data() {
    return {
      // viewer: null,
      path: `${process.env.BASE_URL}webviewer`,
    };
  },
  mounted() {
    // const path = `${process.env.BASE_URL}webviewer`;
    WebViewer(
      { path: this.path, initialDoc: this.initialDoc },
      this.$refs.viewer
    );
    console.log('45', this.path);
  },
  // setup(props) {
  //   const viewer = ref(null);
  //   onMounted(() => {
  //     const path = `${process.env.BASE_URL}webviewer`;
  //     WebViewer({ path, initialDoc: props.initialDoc }, viewer.value);
  //   });
  //   return {
  //     viewer,
  //   };
  // },
};
</script>

<style>
#webviewer {
  height: 800px;
}
</style>
